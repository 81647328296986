import React, { memo, useEffect, useState } from 'react';
import Music from '../../asset/img/music.png';
import MusicBg from '../../asset/img/musicBg.png';
import styled from 'styled-components';

const StyledMusic = styled('div')`
  position: fixed;
  top: 0.18rem;
  right: 0;
  width: 0.84rem;
  height: 0.66rem;
  z-index: 10000;
  img {
    width: 0.84rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .musicImg {
    width: 0.54rem;
    top: 0.06rem;
    left: 0.06rem;
  }
  .animationImg {
    animation: rotateAnimate 5s linear infinite;
  }

  @keyframes rotateAnimate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

let windoAudio = null;
let windoBgAudio = null;

const AudioMemo = memo(({ src, isLast }) => {
  const [audio, setAudio] = useState(null);
  const [bgAudio, setBgAudio] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const [bgIsPlay, setBgIsPlay] = useState(false);
  const [time, setTime] = useState(null);
  useEffect(() => {
    audio?.pause();
    setAudio(null);
    clearTimeout(time);
    setTime(null);
    setIsPlay(false);
    setTimeout(() => {
      getAudio();
    });
  }, [src]);

  // 构建背景音乐对象
  useEffect(() => {
    if (!bgIsPlay) {
      windoBgAudio = new Audio(
        'https://contents02.zijing365.com.cn/zijingyun/huiben/nenglishu.mp3'
      );
      windoBgAudio.loop = true;
      setBgAudio(windoBgAudio);
      setTimeout(async () => {
        try {
          await windoBgAudio.play();
          setBgIsPlay(true);
        } catch (err) {
          console.log(err);
        }
      });
    }
  }, [src]);

  useEffect(() => {
    return () => {
      windoAudio?.pause();
      windoBgAudio?.pause();
      setAudio(null);
      setBgAudio(null);
      windoBgAudio.src = '';
      windoBgAudio = null;
    };
  }, []);

  const getAudio = () => {
    const newAudio = new Audio(src);
    newAudio.addEventListener('canplay', getLength(newAudio));
  };
  const getLength = async (newAudio) => {
    try {
      await newAudio.play();
      let timeIn = setInterval(() => {
        const length = newAudio.duration;
        if (length > 0) {
          clearInterval(timeIn);
          timeIn = null;
          setIsPlay(true);
          audioEnd(length);
        }
        console.log(length);
      }, 200);
    } catch (err) {
      console.log(err);
    }
    setAudio(newAudio);
    windoAudio = newAudio;
  };

  const audioEnd = (time) => {
    console.log(time);
    setTime(
      setTimeout(() => {
        setIsPlay(false);
        isLast();
      }, time * 1000)
    );
  };

  const playAudio = () => {
    if (isPlay) {
      setIsPlay(false);
      audio.pause();
      clearTimeout(time);
      setTime(null);
    } else {
      audio.play();
      audio.addEventListener('canplay', () => {
        const location =
          audio.currentTime == 0 ? audio.duration : audio.duration - audio.currentTime;
        audioEnd(location);
        setIsPlay(true);
      });
      if (!bgIsPlay) {
        bgAudio.play();
        setBgIsPlay(true);
      }
    }
  };

  return (
    <>
      {src && (
        <StyledMusic onClick={playAudio}>
          <img src={MusicBg} alt="" />
          <img
            src={Music}
            style={{ animationPlayState: isPlay ? 'running' : 'paused' }}
            className={`musicImg ${audio == null ? '' : 'animationImg'}`}
            alt=""
          />
        </StyledMusic>
      )}
    </>
  );
});

export default AudioMemo;
