/* eslint-disable no-undef */
import wx from 'weixin-js-sdk';
// import request from '../request';
import requestBack from '../requestBack';
import { baseURL, host } from '../request';

const INIT_WX_URL = window.location.href;

(function (w) {
  function isWeixn() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
      return true;
    } else {
      return false;
    }
  }
  function device() {
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      return 'ios';
    }
    return null;
  }
  w.isWeixn = isWeixn();
  w.device = device();
})(window);

const wxFnConfig = ({ appId, timestamp, nonceStr, signature }) => {
  // alert(`${appId}-${timestamp}-${nonceStr}-${signature}`);
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
    appId, // 必填，公众号的唯一标识
    timestamp, // 必填，生成签名的时间戳
    nonceStr, // 必填，生成签名的随机串
    signature, // 必填，签名，
    jsApiList: ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData']
  });
};

const wxConfig = (href) =>
  new Promise((resolve, reject) => {
    requestBack
      .get(`https://api.1d1d100.com/weixin/jssdkconf?url=${encodeURIComponent(href)}`)
      .then((prams) => {
        const res = prams.data;
        if (res.code === 0) {
          wxFnConfig(res);
          wx.ready(() => {
            wx.updateAppMessageShareData({
              title: '把自信、勇气和乐观送给孩子', // 分享标题
              desc: '1分钟定制心理童话剧', // 分享描述
              link: `${baseURL}/weixin/oauth?targetUrl=${host}/?goodsId=1`, // 分享链接
              imgUrl: 'https://file.1d1d100.com/2020/12/24/92cfd955c5874def9d8fa75efc1059ed.png', // 分享图标
              type: 'link', // 分享类型,music、video或link，不填默认为link
              success() {}
            });

            wx.updateTimelineShareData({
              title: '把自信、勇气和乐观送给孩子', // 分享标题
              link: `${baseURL}/weixin/oauth?targetUrl=${host}/?goodsId=1`, // 分享链接
              imgUrl: 'https://file.1d1d100.com/2020/12/24/92cfd955c5874def9d8fa75efc1059ed.png', // 分享图标
              success() {}
            });
            resolve(wx);
          });
          wx.error(reject);
        } else {
          reject(res.msg);
        }
      })
      .catch(reject);
  });
export const wxSign = (href, cb) => {
  if (!window.isWeixn) return cb();
  const error = (href) => {
    wxConfig(href)
      .then(cb)
      .catch(() => console.log(href));
  };
  if (window.device === 'ios') {
    // ios
    wxConfig(INIT_WX_URL)
      .then(cb)
      .catch((err) => {
        // alert(JSON.stringify(err));
        // error(INIT_WX_URL);
        console.log(err);
      });
  } else {
    wxConfig(href)
      .then(cb)
      .catch(() => error(INIT_WX_URL));
  }
};

const wxPay = (options, success, error) => {
  WeixinJSBridge.invoke('getBrandWCPayRequest', JSON.parse(options), function (res) {
    WeixinJSBridge.log(res.err_msg);
    if (res.err_msg == 'get_brand_wcpay_request:ok') {
      success && success();
    } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
      console.log('取消支付');
    } else {
      error && error();
    }
  });
};

export default wxPay;
