import styled from 'styled-components';

const StyledWrapper = styled.section`
  padding: 0.3rem;
  background: #fff;
  border-radius: 0.2rem;
  margin: 0.24rem;
  &[type='form'] {
    padding: 0.2rem 0 0.2rem 0.1rem;
    .am-list-item .am-input-label {
      color: #333;
    }
    .am-list-item {
      &.image-upload {
        .am-list-line {
          padding-top: 5px;
          align-items: flex-start;
          .am-list-extra {
            overflow: visible;
            .am-image-picker {
              margin-left: -12px;
              .am-image-picker-list {
                padding-top: 5px;
              }
            }
          }
        }
      }

      .am-list-line {
        .am-list-content {
          color: #333;
        }

        .am-list-extra {
          flex: 2;
          text-align: left;
          color: #bbb;
          line-height: 1.2;
          font-size: 17px;
        }
      }
    }
    .am-list-body:before,
    .am-list-body:after {
      content: none;
    }
  }
  &.with_head {
    margin-top: 0.94rem;
    position: relative;
  }

  .head {
    position: absolute;
    left: 0;
    top: -0.8rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: #999999;
    line-height: 0.4rem;
  }
`;

export default function Block({ title = '', children, className, ...rest }) {
  return (
    <StyledWrapper className={`${title && 'with_head'} ${className}`} {...rest}>
      {title && <h2 className="head">{title}</h2>}
      {children}
    </StyledWrapper>
  );
}
