import styled from 'styled-components';

const StyledWrapper = styled.section`
  background: #fff;
  min-height: 100vh;
  margin: 0 0.36rem;
  section {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .intro {
    display: flex;
    flex-direction: column;
  }
  .detail {
    border-radius: 1rem;
  }
  .btn {
    text-align: center;
    padding: 0 0 0.24rem 0;
  }
  .inputItem {
    padding: 0.28rem 0.3rem !important;
  }
  .am-list-item {
    background: #f8f8f8;
    padding: 0 0.3rem !important;
    border-radius: 0.15rem;
    margin-top: 0.18rem;
  }
  .labelName {
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 0.36rem;
    display: inline-block;
  }
  .titleInfo {
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 0.36rem;
    line-height: 0.5rem;
    div {
      font-weight: 400;
      color: #999999;
      font-size: 0.28rem;
      margin-top: 0.12rem;
    }
  }
  .btns {
    height: 10vh;
    margin: 0.3rem auto 0.3rem;
    /* margin: 0.6rem; */
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    div {
      width: 2.96rem;
      height: 0.9rem;
      background: linear-gradient(180deg, #ffda2a 0%, #ffb128 100%);
      border-radius: 0rem 1rem 1rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 0.28rem;
      font-weight: 500;
      box-shadow: 0rem 0.12rem 0.3rem 0rem rgba(0, 0, 0, 0.18);
    }
    div:first-child {
      width: 2rem;
      background: linear-gradient(180deg, #ff7c00 0%, #ff5300 100%);
      border-radius: 1rem 0rem 0rem 1rem;
    }
  }
`;

export default StyledWrapper;
