import styled from 'styled-components';

const StyledWrapper = styled.section`
  background: #f4f4f4;
  min-height: 100vh;
  .good {
    display: flex;
    flex-direction: column;
    .info {
      display: flex;
      align-items: center;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid #eaeaea;
      .cover {
        width: 1.26rem;
        height: 1.26rem;
        margin-right: 0.3rem;
      }
      .detail {
        display: flex;
        flex-direction: column;
        width: 100%;
        .title {
          margin-bottom: 0.06rem;
          display: flex;
          justify-content: space-between;
          .txt {
            font-size: 0.28rem;
            font-weight: 500;
            color: #333333;
            line-height: 0.4rem;
          }
          .count {
            font-size: 0.24rem;
            font-weight: 400;
            color: #333333;
            line-height: 0.34rem;
          }
        }
        .tags {
          font-size: 0.24rem;
          font-weight: 400;
          color: #999999;
          line-height: 0.34rem;
          margin-bottom: 0.06rem;
        }
        .price {
          font-size: 0.28rem;
          font-weight: 500;
          color: #fe3400;
          line-height: 0.4rem;
          span:last-child {
            /* &:before {
              content: '¥';
              color: #fe3400;
              font-size: 0.2rem;
            } */
          }
        }
      }
    }
    .pay {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0 0.15rem 0;
      .wx {
        display: flex;
        img {
          width: 0.42rem;
          height: 0.42rem;
        }
        .txt {
          margin-left: 0.18rem;
          font-size: 0.28rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.4rem;
        }
      }
      .total {
        font-size: 0.24rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.33rem;
        .num {
          padding-left: 0.12rem;
          font-size: 0.38rem;
          font-weight: 500;
          color: #fe3400;
          line-height: 0.54rem;
          &:before {
            content: '¥';
            color: #fe3400;
            font-size: 0.24rem;
          }
        }
      }
    }
  }
  .imgList {
    font-size: 0;
    img {
      width: 100vw;
    }
  }
  .btn {
    position: fixed;
    left: 0;
    bottom: 30px;
    width: 100%;
    text-align: center;
  }
  .originalPrice {
    text-decoration: line-through;
    font-size: 0.28rem;
    color: #999999;
    margin-right: 0.12rem;
  }
  .couponsCode {
    border-bottom: 1px solid #eaeaea;
    div {
      padding-left: 0 !important;
    }
  }
`;

export default StyledWrapper;
