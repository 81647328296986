import { DatePicker } from 'antd-mobile';
import { ListItem } from './styled';

const DatePickerItem = ({ children, ...props }) => {
  return (
    <DatePicker mode="date" title={children} extra="请选择" {...props}>
      <ListItem value={props.value} arrow="horizontal">
        {children}
      </ListItem>
    </DatePicker>
  );
};

export default DatePickerItem;
