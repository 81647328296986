import { Picker } from 'antd-mobile';
import { ListItem } from './styled';

const PickerItem = ({ data, children, ...props }) => {
  return (
    <Picker data={data} title={children} extra="请选择" {...props}>
      <ListItem value={props.value} arrow="horizontal">
        {children}
      </ListItem>
    </Picker>
  );
};

export default PickerItem;
