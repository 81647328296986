import { useHistory } from 'react-router-dom';

import { InputItem, Toast } from 'antd-mobile';
import Form, { Field } from 'rc-field-form';
// import Button from '../../component/Button';
// import TopBar from '../../component/TopBar';
import Block from '../../component/Block';
// import Tip from '../../component/Tip';
import { List } from '../../component/styled';
import ImagePickerItem from '../../component/ImagePickerItem';
import PickerItem from '../../component/PickerItem';
import DatePickerItem from '../../component/DatePickerItem';
import Carousels from './Carousels';
// import styled from 'styled-components';
import StyledWrapper from './styled';

import request from '../../common/request';
import dayjs from 'dayjs';
import gender from '../../common/gender_data';
import getUrlSearchParams from '../../common/utils/getUrlSearchParams';

// const StyledButton = styled(Button)`
//   padding-left: 1.39rem;
//   padding-right: 1.39rem;
// `;

// 校验表单数据
function checkFormChild(values) {
  if (!values.nickname) {
    return Toast.fail('宝贝昵称不能为空');
  }
  if (!values.birthday) {
    return Toast.fail('宝贝生日不能为空');
  }
  if (!values.gender) {
    return Toast.fail('宝贝性别不能为空');
  }
  if (!values.photo || values.photo.length === 0) {
    return Toast.fail('宝贝照片不能为空');
  }
}

// 提交预览信息
const submit = (values, success) => {
  // query
  const { goodsId } = getUrlSearchParams();
  const {
    nickname,
    birthday,
    gender: [gender],
    photo: [file] = [],
    model_id
  } = values || {};
  const form = new FormData();
  form.append('nickname', nickname);
  form.append('birthday', dayjs(birthday).format('YYYY-MM-DD'));
  form.append('gender', gender);
  form.append('photo', file.file);
  form.append('goods_id', goodsId);
  form.append('page', 1);
  form.append('model_id', model_id);
  Toast.loading('提交中...', 0);
  request({
    url: '/weixin/preview',
    method: 'POST',
    data: form,
    contentType: 'multipart/form-data',
    withCredentials: true,
    crossDomain: true
  })
    .then((res) => {
      if (res.data.code === 0) {
        Toast.success('提交成功', 1, () => {
          success(gender);
        });
      } else {
        Toast.fail(res.data.msg);
      }
    })
    .catch(() => {
      Toast.fail('网络异常');
    });
};

export default function PrePreview() {
  const history = useHistory();
  // query
  const { goodsId } = getUrlSearchParams();
  // form
  const [form] = Form.useForm();
  const submitForm = async () => {
    try {
      const values = await form.validateFields();
      const model_id = Number(values.gender) === 1 ? 1 : 3;
      submit(
        {
          model_id,
          ...values
        },
        (gender) => {
          history.push(`/pr?tab=0&gender=${gender}&goodsId=${goodsId}`);
        }
      );
    } catch (errors) {
      checkFormChild(errors.values);
    }
  };

  return (
    <StyledWrapper>
      <Form form={form}>
        {/* <TopBar title="预览内容" /> */}
        <Block>
          <Carousels
            list={[
              'https://file.1d1d100.com/2021/12/8/ff25ba52efb9497e98a9baeee0d11b0a.jpg',
              'https://file.1d1d100.com/2021/12/8/e6aca262537742d3a4cb40e0bfcc2e82.jpg',
              'https://file.1d1d100.com/2021/12/8/f72cb4098adf49fda3ac8690b03834ca.jpg',
              'https://file.1d1d100.com/2021/12/8/b1d4d477ba91424c97d915d696590220.jpg'
            ]}
          />
          <div className="titleInfo">
            心理童话剧《生日的秘密》：发现自信、勇气和乐观的力量
            <div>
              让孩子在童话场景中，沉浸式地进行积极心理品质的学习和内化塑造。AI将会根据孩子的名字、出生日期和形象定制专属内容。提交信息后可立即观看
            </div>
          </div>
        </Block>
        <Block type="form" title="">
          <List>
            <span className="labelName" style={{ marginTop: '0.12rem' }}>
              宝贝昵称
            </span>
            <Field
              name="nickname"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputItem className="inputItem" placeholder="请输入宝贝昵称" maxLength="7" />
            </Field>
            <span className="labelName">出生日期</span>
            <Field
              name="birthday"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <DatePickerItem className="inputItem"></DatePickerItem>
            </Field>
            <span className="labelName">宝贝性别</span>

            <Field
              name="gender"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <PickerItem className="inputItem" data={gender} cols="1"></PickerItem>
            </Field>
            <span className="labelName">宝贝照片</span>

            <Field
              name="photo"
              valuePropName="files"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <ImagePickerItem></ImagePickerItem>
            </Field>
          </List>
        </Block>
        <div className="btns animate__animated animate__slideInUp">
          <div onClick={submitForm}>观看童话剧</div>
          <div
            onClick={() => {
              history.push(`/order?goodsId=${goodsId}`);
            }}
          >
            定制个性化剧本
          </div>
          {/* <StyledButton onClick={submitForm}>观看孩子专属生日童话剧</StyledButton> */}
        </div>
      </Form>
    </StyledWrapper>
  );
}
