/* eslint-disable no-undef */
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import Home from './page/Home';
import Preview from './page/Preview';
import PrePreview from './page/PrePreview';
import Invite from './page/Invite';
import PayOrder from './page/PayOrder';
import OrderList from './page/OrderList';
import Custom from './page/Custom';
import ScrollToTop from './component/ScrollToTop';

import { baseURL, host } from './common/request';
import { wxSign } from './common/utils/wxPay';
import { useEffect } from 'react';

//调用微信JS api 支付
function sendMessage(wx) {
  const config = {
    // appid: '',
    imgUrl: 'https://file.1d1d100.com/2020/12/24/92cfd955c5874def9d8fa75efc1059ed.png',
    // img_width: '120',
    // img_height: '120',
    link: `${baseURL}/weixin/oauth?targetUrl=${host}/?goodsId=1`,
    desc: '1分钟定制心理童话剧',
    title: '把自信、勇气和乐观送给孩子'
  };
  // WeixinJSBridge.on('menu:share:appmessage', function () {
  //   WeixinJSBridge.invoke('sendAppMessage', config);
  // });
  // WeixinJSBridge.on('menu:share:timeline', function () {
  //   WeixinJSBridge.invoke('shareTimeline', config);
  // });
  wx.updateAppMessageShareData(config);
  wx.updateTimelineShareData(config);
}
// function callpay() {
//   if (typeof WeixinJSBridge == 'undefined') {
//     if (document.addEventListener) {
//       document.addEventListener('WeixinJSBridgeReady', sendMessage, false);
//     } else if (document.attachEvent) {
//       document.attachEvent('WeixinJSBridgeReady', sendMessage);
//       document.attachEvent('onWeixinJSBridgeReady', sendMessage);
//     }
//   } else {
//     sendMessage();
//   }
// }

function App() {
  useEffect(() => {
    wxSign(location.href, (wx) => {
      if (wx) {
        sendMessage(wx);
      }
    });
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          {/* <Home /> */}
          <PrePreview />
        </Route>
        <Route path="/invite">
          <Invite />
        </Route>
        <Route path="/pp">
          <PrePreview />
        </Route>
        <Route path="/pr">
          <Preview />
        </Route>
        <Route path="/order">
          <PayOrder />
        </Route>
        <Route path="/custom">
          <Custom />
        </Route>
        <Route path="/ol">
          <OrderList />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
