export default [
  {
    label: '男',
    value: 1
  },
  {
    label: '女',
    value: 2
  }
];
