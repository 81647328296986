import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { For } from 'react-loops';
import styled from 'styled-components';
import StyledWrapper from './styled';
import { Toast } from 'antd-mobile';
import Button from '../../component/Button';
import Poster from '../../component/poster';
import ImageBanner from '../../asset/img/banner.invite.png';

import request from '../../common/request';

const DownloadButton = styled(Button)`
  padding-left: 1.12rem;
  padding-right: 1.12rem;
`;

function imgLoad(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = resolve;
    img.onerror = reject;
  });
}

// 海报弹窗
function poster(url) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function close() {
    ReactDOM.unmountComponentAtNode(div);
    if (div && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }
  ReactDOM.render(<Poster url={url} onCancel={close} />, div);
}

// 获取海报
function getPoster() {
  Toast.loading('获取中...', 0);
  request
    .get('/weixin/poster')
    .then((res) => {
      if (res.data.code === 0) {
        imgLoad(res.data.data.url)
          .then(() => {
            Toast.hide();
            poster(res.data.data.url);
          })
          .catch(() => Toast.fail('网络异常'));
      } else {
        Toast.fail(res.data.msg);
      }
    })
    .catch(() => Toast.fail('网络异常'));
}

// 获取被邀请人
function getInvitees(success) {
  request
    .get('/weixin/my/invites')
    .then((res) => {
      if (res.data.code === 0) {
        success(res.data.data);
      } else {
        Toast.fail(res.data.msg);
      }
    })
    .catch(() => Toast.fail('网络异常'));
}

// 格式化被邀请人员数据
function syncInvitees(invitees) {
  const pls = new Array(3).fill({});
  return pls.map((pl, index) => ({ ...(invitees[index] || {}) }));
}

export default function Invite() {
  // 页面加载动画
  const [pageReady, setPageReady] = useState(false);
  // 被邀请人员
  const [invitees, setInvitees] = useState([]);
  const handleAniEnd = () => {
    setPageReady(true);
  };
  useEffect(() => {
    getInvitees((result) => setInvitees(result));
  }, []);
  const history = useHistory();
  const receive = () => {
    history.push('/order?goodsId=1&isReward=1');
  };
  return (
    <StyledWrapper onAnimationEnd={handleAniEnd} className="animate__animated animate__zoomIn">
      <div className="banner">
        <img src={ImageBanner} alt="邀请Banner" />
        <div className="tip">
          下载你专属的海报转发给好友， 3位好友完成支付后，你将获得 一次免费的定制奖励
        </div>
      </div>
      <DownloadButton
        className={`animate__animated ${pageReady ? 'animate__zoomIn' : 'invisible'}`}
        onClick={getPoster}
      >
        立即下载海报邀请
      </DownloadButton>
      <div className="progress">
        <div className="tip">好友定制进度{invitees.length}/3</div>
        <ul className="friends">
          <For
            of={syncInvitees(invitees)}
            as={({ head_image }) => {
              if (typeof head_image === 'undefined') {
                return <li className="friend none">未订制</li>;
              }
              return (
                <li className="friend">
                  <div className="head">
                    <img src={head_image} alt="朋友头像" />
                  </div>
                </li>
              );
            }}
          />
        </ul>
        <Button
          className={`animate__animated ${pageReady ? 'animate__zoomIn' : 'invisible'}`}
          disabled={invitees.length < 3}
          onClick={receive}
        >
          领取奖励
        </Button>
      </div>
    </StyledWrapper>
  );
}
