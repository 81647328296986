import styled from 'styled-components';

import { For } from 'react-loops';

const Picker = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  .hair {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0.21rem;
    margin-right: 0.18rem;
    cursor: pointer;
    &.selected {
      .title {
        background: linear-gradient(90deg, #ff7c00 0%, #ff5300 100%);
        color: #fff;
        &:before {
          content: 'ヘ';
          color: #ff5300;
          transform: rotateX(180deg);
          font-weight: bold;
          font-size: 0.2rem;
          background-color: #fff;
          margin-top: -0.14rem;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    img {
      width: 3.12rem;
      height: 3.12rem;
      border-radius: 0.06rem;
    }
    .title {
      background-color: #f6f6f6;
      border-radius: 0.3rem;
      font-size: 0.26rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.38rem;
      margin-top: 0.24rem;
      padding: 0.08rem 0.18rem 0.08rem 0.54rem;
      position: relative;
      transition: all 0.2s ease-in-out;
      &:before {
        box-sizing: border-box;
        position: absolute;
        left: 0.16rem;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        border: 1px solid #999;
      }
    }
  }
`;

const HairsPicker = ({ value, data = [], onChange }) => {
  return (
    <Picker>
      <For
        of={data}
        as={({ id, demo_image, name }) => (
          <div className={`hair ${id == value && 'selected'}`} onClick={() => onChange(id)}>
            <img src={demo_image} alt="发型" />
            <span className="title">{name}</span>
          </div>
        )}
      />
    </Picker>
  );
};

export default HairsPicker;
