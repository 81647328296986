import styled from 'styled-components';

const StyledWrapper = styled.section`
  background-color: #f4f4f4;
  min-height: 100vh;
  .order {
    display: flex;
    flex-direction: column;
    font-size: 0.2rem;
    time {
      font-size: 0.26rem;
      font-weight: 400;
      color: #666666;
      line-height: 0.38rem;
    }
    .info {
      display: flex;
      padding: 0.3rem 0 0.12rem 0;
      .img {
        border-radius: 0.06rem;
        width: 1.48rem;
        height: 1.48rem;
        margin-right: 0.3rem;
        object-fit: cover;
      }
      .detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .title {
          font-size: 0.3rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.42rem;
          margin-bottom: 0.06rem;
          display: flex;
        }
        .title-text {
          flex: 1;
        }
        .title-extra {
          flex: 0 0 auto;
          color: red;
        }
        .desc {
          font-size: 0.24rem;
          font-weight: 400;
          color: #666666;
          line-height: 0.34rem;
          margin-top: -0.2rem;
        }
        .ext {
          display: flex;
          justify-content: space-between;
          .price {
            font-size: 0.2rem;
            font-weight: 500;
            color: #333333;
            line-height: 0.42rem;
          }
          .count {
            font-size: 0.24rem;
            font-weight: 400;
            color: #999999;
            line-height: 0.34rem;
          }
        }
      }
    }
  }
  .ant-empty {
    margin: 100px 8px;
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
  }

  .ant-empty-image {
    height: 100px;
    margin-bottom: 20px;
  }

  .ant-empty-image img {
    height: 100%;
  }

  .ant-empty-description {
    margin: 0;
    padding: 0 25px 14px 25px;
    font-size: 16px;
    color: #666;
    text-align: center;
  }
`;

export default StyledWrapper;
