import ReactDOM from 'react-dom';
import SimpleCrop from './SimpleCrop';

export const cropper = (url, onEnter, onCancel) => {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function close() {
    ReactDOM.unmountComponentAtNode(div);
    if (div && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }
  ReactDOM.render(
    <SimpleCrop
      src={url}
      size={{
        width: 720,
        height: 720
      }}
      borderWidth={3}
      borderColor="#ffffff"
      coverColor="rgba(0, 0, 0, 0.6)"
      rotateSlider={false}
      maxScale={2}
      cropCallback={(canvas) => {
        close();
        onEnter && onEnter(canvas.toDataURL('image/png', 1));
      }}
      closeCallback={() => {
        close();
        onCancel && onCancel();
      }}
    />,
    div
  );
  return {
    close
  };
};

/**
 * 待优化
 */
