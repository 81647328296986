import styled from 'styled-components';

const StyledWrapper = styled.div`
  padding: 0.19rem 0.3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
  .back {
    position: absolute;
    left: 0.3rem;

    font-size: 0.32rem;
    font-weight: 400;
    color: #999999;
    line-height: 0.5rem;
  }
  .title {
    font-size: 0.32rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.5rem;
  }
`;
export default function TopBar({ title = 'AI绘本' }) {
  const handleBack = () => {
    history.go(-1);
  };
  return (
    <StyledWrapper>
      <button className="back" onClick={handleBack}>
        返回
      </button>
      <span className="title">{title}</span>
    </StyledWrapper>
  );
}
