import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Carousels from '../PrePreview/Carousels';
import { InputItem, TextareaItem, Toast } from 'antd-mobile';
import Form, { Field } from 'rc-field-form';

import styled from 'styled-components';
import StyledWrapper from './styled';
import Button from '../../component/Button';
// import TopBar from '../../component/TopBar';
import Block from '../../component/Block';
import Tip from '../../component/Tip';
import { List } from '../../component/styled';
import PickerItem from '../../component/PickerItem';

import useSWR from 'swr';
import request from '../../common/request';
import getUrlSearchParams from '../../common/utils/getUrlSearchParams';
import wxPay from '../../common/utils/wxPay';

const StyledButtonPlaceholder = styled.div`
  /* height: 1rem;
  margin: 0.84rem 0; */
`;
const StyledButton = styled.div`
  .button {
    height: 1rem;
    padding-left: 2.36rem;
    padding-right: 2.36rem;
  }
`;

const PickerLocationItem = ({ children, ...props }) => {
  const [tree, setTree] = useState([]);
  useEffect(() => {
    import(/* webpackChunkName: 'location' */ '../../common/utils/location').then((module) => {
      setTree(module.default);
    });
  }, []);
  return (
    <PickerItem data={tree} {...props}>
      {children}
    </PickerItem>
  );
};

// 获取商品信息
function fetch(api) {
  return new Promise((resolve, reject) => {
    request
      .get(api)
      .then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data);
        }
        reject(res.data.msg);
      })
      .catch(() => {
        reject('网络异常');
      });
  });
}

// 提交收货信息
function submitGoodsInfo(opts, success) {
  const {
    location: [province_id, city_id, country_id],
    ...props
  } = opts;
  Toast.loading('支付中...', 0);
  request
    .post('/weixin/order', {
      ...props,
      province_id,
      city_id,
      country_id
    })
    .then((res) => {
      if (res.data.code === 0) {
        success(res.data.data);
      } else {
        Toast.fail(res.data.msg);
      }
    })
    .catch(() => Toast.fail('网络异常'));
}

// 校验表单数据
function checkFormChild(values) {
  if (!values.name) {
    return Toast.fail('收货人姓名不能为空');
  }
  if (!values.mobile) {
    return Toast.fail('联系方式不能为空');
  }
  if (!values.location) {
    return Toast.fail('所在区域不能为空');
  }
  if (!values.address) {
    return Toast.fail('详细地址不能为空');
  }
}

export default function PayOrder() {
  const history = useHistory();
  const [couponsCode, setCouponsCode] = useState('');
  const { goodsId, channelId, isReward } = getUrlSearchParams();
  // fetch
  const { data = {} } = useSWR(`/weixin/goods/${goodsId}`, fetch);
  const { name, logo, norms, price } = data;
  // form
  const [form] = Form.useForm();
  const handlePay = async () => {
    try {
      const values = await form.validateFields();
      values.goodsId = goodsId;
      values.couponsCode = couponsCode;
      submitGoodsInfo(
        Object.assign(values, { goods_id: goodsId }, channelId ? { channel_id: channelId } : {}, {
          is_reward: !!Number(isReward)
        }),
        (payInfo) => {
          const { id, json } = payInfo;
          Toast.hide();
          if (Number(isReward) === 1) {
            history.push(`/custom?goodsId=${goodsId}&orderId=${id}`);
          } else {
            wxPay(
              json,
              () => {
                history.push(`/custom?goodsId=${goodsId}&orderId=${id}`);
              },
              () => {
                Toast.fail('支付失败');
              }
            );
          }
        }
      );
    } catch (errors) {
      console.log(errors);
      checkFormChild(errors.values);
    }
  };
  return (
    <StyledWrapper>
      {/* <TopBar title="支付" /> */}
      <Block style={{ padding: 0, background: '#f4f4f4' }}>
        <Carousels
          list={[
            'https://file.1d1d100.com/2021/12/17/3eeed3116b9146daaba73dbbb3489269.jpg',
            'https://file.1d1d100.com/2021/12/9/66f3ea3fe0c1431d99c648a513174389.jpg',
            'https://file.1d1d100.com/2021/12/9/c3c3bf5b10b24158944e329f2bd8efd9.jpg',
            'https://file.1d1d100.com/2021/12/9/5bd313d6fd314f4f96f6d67569564e49.jpg',
            'https://file.1d1d100.com/2021/12/9/742bfd2e47f94a7ab56e4f07eb114139.jpg'
          ]}
          width="7.4rem"
          height="4.7rem"
        />
      </Block>
      <Block type="form" title="收货地址">
        <Form form={form}>
          <List>
            <Field
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputItem placeholder="请输入收货人姓名">收货人</InputItem>
            </Field>
            <Field
              name="mobile"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputItem type="number" maxLength="11" placeholder="请输入收货人联系方式">
                联系方式
              </InputItem>
            </Field>
            <Field
              name="location"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <PickerLocationItem>所在地区</PickerLocationItem>
            </Field>
            <Field
              name="address"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <TextareaItem title="详细地址" placeholder="请输入详细地址" autoHeight />
            </Field>
          </List>
        </Form>
      </Block>
      <Block className="good" title="商品信息">
        <div className="info">
          <img className="cover" src={logo} alt="商品配图" />
          <div className="detail">
            <div className="title">
              <span className="txt">{name}</span> <span className="count">[数量] x1</span>
            </div>
            <div className="tags">{norms}</div>
            <div className="price">
              {/* <span className="originalPrice">{original_price}</span>￥{price} */}￥{price}
            </div>
          </div>
        </div>
        <div className="couponsCode">
          <InputItem
            type="text"
            value={couponsCode}
            onChange={(val) => {
              setCouponsCode(val);
            }}
            maxLength="10"
            placeholder="请输入优惠码"
          >
            优惠码
          </InputItem>
        </div>
        <div className="pay">
          <div className="wx">
            <img
              src="https://file.1d1d100.com/2020/11/23/198f3656f18544688588957d861b2267.png"
              alt="微信图标"
            />
            <div className="txt">微信支付</div>
          </div>
          <div className="total">
            总计&nbsp;&nbsp;
            {/* <span className="originalPrice">{original_price}</span> */}
            <span className="num">{Number(isReward) === 1 ? 0 : price}</span>
          </div>
        </div>
      </Block>
      <Block>
        <Tip
          tip={[
            '每件产品都是单独制作的，因此不可退款退货敬请谅解。制作周期为8-12个工作日，请耐心等待收货',
            '如有制作质量问题，收货后7天内可换货重做',
            '下单支付后，将跳转你的定制信息编辑和确认页面'
          ]}
        />
      </Block>
      <div className="imgList">
        <img
          src="https://file.1d1d100.com/2021/12/17/42c6224bf5d54f2299dd6e8a29f9c2b5.jpg"
          alt=""
        />
        <img
          src="https://file.1d1d100.com/2021/12/17/30e2b97d2c38474a84dba35ca6aeaf10.jpg"
          alt=""
        />
        <img
          src="https://file.1d1d100.com/2021/12/20/633a6494833640b4a6a58a53a830fce8.jpg"
          alt=""
        />
        <img src="https://file.1d1d100.com/2021/12/9/40604282ca6c4aeb898bc6cd8f097d21.jpg" alt="" />
        <img src="https://file.1d1d100.com/2021/12/9/b205b63a123343b5b0d9f3f138aec263.jpg" alt="" />
      </div>
      <StyledButtonPlaceholder />
      <div className="btn animate__animated animate__slideInUp">
        <StyledButton>
          <Button onClick={handlePay}>马上下单</Button>
        </StyledButton>
      </div>
    </StyledWrapper>
  );
}
