import styled from 'styled-components';

const StyledWrapper = styled.section`
  background: #f4f4f4;
  min-height: 100vh;
  .btn {
    margin: 0.84rem 0;
    text-align: center;
  }
`;

export default StyledWrapper;
