import axios from 'axios';

export const baseURL = '//album.1d1d100.net';
export const host = '//gmc.1d1d100.net';

const instance = axios.create({
  timeout: 10 * 60 * 1000,
  withCredentials: true
});

export default instance;
