import styled from 'styled-components';

const StyledWrapper = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pageNum {
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: fixed;
    top: 0.3rem;
    left: 0.3rem;
    z-index: 10;
  }
  .imgText {
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    line-height: 0.46rem;
    width: 6.3rem;
    max-height: 2.3rem;
    overflow-y: scroll;
    z-index: 100;
    position: absolute;
    top: 9rem;
  }
  .slide {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .swiper-container {
      width: 100%;
      overflow: initial;
      .swiper-slide {
        height: 100vh;
      }
      .img {
        display: flex;
        min-height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 100%;
          height: auto;
        }
        width: 100%;
      }
      .swiper-pagination {
        position: fixed;
        bottom: 18vh;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        height: 0.25rem;
        display: flex;
        align-items: center;
        padding: 0.06rem;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.12rem;
        .swiper-pagination-bullet {
          display: block;
          width: 0.12rem;
          height: 0.12rem;
          border-radius: 50%;
          background-color: #fff;
          transition: background-color 0.5s;
          opacity: 1;
          &.swiper-pagination-bullet-active {
            background-color: #ff5300;
          }
        }
      }
    }
  }
  .btns {
    position: fixed;
    bottom: 0.6rem;
    height: 10vh;
    margin: 0 auto;
    margin-top: 0.6rem;
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    div {
      width: 2.96rem;
      height: 0.9rem;
      background: linear-gradient(180deg, #ffda2a 0%, #ffb128 100%);
      border-radius: 0rem 1rem 1rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 0.28rem;
      font-weight: 500;
      box-shadow: 0rem 0.12rem 0.3rem 0rem rgba(0, 0, 0, 0.18);
    }
    div:first-child {
      width: 2rem;
      background: linear-gradient(180deg, #ff7c00 0%, #ff5300 100%);
      border-radius: 1rem 0rem 0rem 1rem;
    }
  }
  .playMusicHint {
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
    div {
      position: absolute;
      bottom: 1.6rem;
      width: 100%;
      text-align: center;
    }
  }
  .playMusicHint > img {
    position: absolute;
    top: 0.34rem;
    right: 0.92rem;
  }
`;

export default StyledWrapper;
