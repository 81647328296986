import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import StyledWrapper from './styled';
import { Toast } from 'antd-mobile';
import { For } from 'react-loops';
import TopBar from '../../component/TopBar';
import Block from '../../component/Block';
import Tip from '../../component/Tip';
import Spin from '../../component/Spin';

import useSWR from 'swr';
import request from '../../common/request';

function fetch(api) {
  return new Promise((resolve, reject) => {
    request
      .get(api)
      .then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data);
        }
        reject(res.data.msg);
      })
      .catch(() => {
        reject('网络异常');
      });
  });
}

export default function OrderList() {
  const { data, error, isValidating } = useSWR(`/weixin/orders`, fetch);
  // 监听request error
  useEffect(() => {
    error && Toast.fail(error);
  }, [error]);
  const history = useHistory();
  const toBeImproved = (goodsId, orderId) => {
    history.push(`/custom?goodsId=${goodsId}&orderId=${orderId}`);
  };
  return (
    <StyledWrapper>
      <TopBar title="我的订单" />
      {!data ? (
        <Spin spinning={isValidating} />
      ) : data.length > 0 ? (
        <>
          <Block>
            <Tip tip="定制礼盒的制作周期为7-10个工作日，请耐心等待收货" />
          </Block>
          <For
            of={data}
            as={({
              cover_img,
              created_at,
              goods_name,
              model_name,
              price,
              goods_num,
              order_status,
              order_id,
              goods_id
            }) => (
              <Block
                className="order"
                onClick={() => {
                  if (order_status === 4) {
                    toBeImproved(goods_id, order_id);
                  }
                }}
              >
                <time>{created_at}</time>
                <div className="info">
                  <img src={cover_img} alt="绘本配图" className="img" />
                  <div className="detail">
                    <div className="title">
                      <div className="title-text">{goods_name}</div>
                      {order_status === 4 && <div className="title-extra">待完善</div>}
                    </div>
                    <div className="desc">{model_name}</div>
                    <div className="ext">
                      <span className="price">¥{price}</span>
                      <span className="count">[数量] x{goods_num}</span>
                    </div>
                  </div>
                </div>
              </Block>
            )}
          />
        </>
      ) : (
        <div className="ant-empty ant-empty-normal">
          <div className="ant-empty-image">
            <img src="/static/image/img_noorder_3x.png" />
          </div>
          <p className="ant-empty-description">暂无订单</p>
        </div>
      )}
    </StyledWrapper>
  );
}
