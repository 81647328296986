import styled from 'styled-components';

const StyledWrapper = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      height: 4.4rem;
    }
    .tip {
      padding: 0 0.84rem;
      font-size: 0.26rem;
      font-weight: 400;
      color: #666666;
      line-height: 0.38rem;
      text-align: center;
      margin-top: -0.75rem;
      margin-bottom: 0.42rem;
    }
  }
  .progress {
    width: 6.78rem;
    margin-top: 0.84rem;
    border-top: 1px solid #eaeaea;
    padding: 0.84rem 0.36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tip {
      font-size: 0.28rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.4rem;
      margin-bottom: 0.6rem;
    }
    .friends {
      margin-bottom: 0.84rem;
      display: flex;
      align-items: center;
      font-size: 0.22rem;
      padding: 0;
      .friend {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:last-child) {
          margin-right: 0.48rem;
        }
        &.none {
          border-radius: 50%;
          width: 1.2rem;
          height: 1.2rem;
          font-size: 0.24rem;
          font-weight: 400;
          color: #999;
          line-height: 0.34rem;
          background: #f2f2f2;
          justify-content: center;
        }
        .head {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.32rem;
          height: 1.32rem;
          border-radius: 50%;
          background: linear-gradient(180deg, #ff7c00 0%, #ff5300 100%);
          img {
            border-radius: 50%;
            width: 1.2rem;
            height: 1.2rem;
          }
        }
        .name {
          position: absolute;
          bottom: -0.35rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
`;

export default StyledWrapper;
