import styled from 'styled-components';
import { List as ListStyle } from 'antd-mobile';

export const ListItem = styled(ListStyle.Item)`
  .am-list-extra {
    color: ${(props) => props.value && '#333333'} !important;
  }
`;

export const List = styled(ListStyle)`
  .am-list-line {
    .am-input-label,
    .am-list-content {
      flex: 0 0 auto;
      font-size: 17px;
      line-height: 1.5;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-top: 7px;
      padding-bottom: 7px;
      width: 1.7rem;
      margin: 0;
    }
  }
  .am-textarea-label {
    flex: 0 0 auto;
    font-size: 17px;
    line-height: 1.5;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 9px;
    width: 1.7rem;
    margin: 0;
  }
`;
