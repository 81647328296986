import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    history.listen((state, action) => {
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
