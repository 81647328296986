import styled from 'styled-components';

const SpinStyle = styled.div`
  ${(props) => !props.spinning && 'display:none'};
  padding-top: 5px;
  padding-bottom: 5px;
  transform: scale(0.8);

  .la-ball-beat,
  .la-ball-beat > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .la-ball-beat {
    display: block;
    margin: 0 auto;
    font-size: 0;
    color: #fff;
    & > div {
      &:nth-child(1) {
        color: #ff4f55;
      }
      &:nth-child(2) {
        color: #3e8cff;
      }
      &:nth-child(3) {
        color: #23d181;
      }
    }
  }
  .la-ball-beat.la-dark {
    color: #333;
  }
  .la-ball-beat > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
  }
  .la-ball-beat {
    width: 54px;
    height: 18px;
  }
  .la-ball-beat > div {
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 100%;
    -webkit-animation: ball-beat 0.7s -0.15s infinite linear;
    -moz-animation: ball-beat 0.7s -0.15s infinite linear;
    -o-animation: ball-beat 0.7s -0.15s infinite linear;
    animation: ball-beat 0.7s -0.15s infinite linear;
  }
  .la-ball-beat > div:nth-child(2n-1) {
    -webkit-animation-delay: -0.5s;
    -moz-animation-delay: -0.5s;
    -o-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .la-ball-beat.la-sm {
    width: 26px;
    height: 8px;
  }
  .la-ball-beat.la-sm > div {
    width: 4px;
    height: 4px;
    margin: 2px;
  }
  .la-ball-beat.la-2x {
    width: 108px;
    height: 36px;
  }
  .la-ball-beat.la-2x > div {
    width: 20px;
    height: 20px;
    margin: 8px;
  }
  .la-ball-beat.la-3x {
    width: 162px;
    height: 54px;
  }
  .la-ball-beat.la-3x > div {
    width: 30px;
    height: 30px;
    margin: 12px;
  }
  /*
  * Animation
  */
  @-webkit-keyframes ball-beat {
    50% {
      opacity: 0.2;
      -webkit-transform: scale(0.75);
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @-moz-keyframes ball-beat {
    50% {
      opacity: 0.2;
      -moz-transform: scale(0.75);
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      -moz-transform: scale(1);
      transform: scale(1);
    }
  }
  @-o-keyframes ball-beat {
    50% {
      opacity: 0.2;
      -o-transform: scale(0.75);
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes ball-beat {
    50% {
      opacity: 0.2;
      -webkit-transform: scale(0.75);
      -moz-transform: scale(0.75);
      -o-transform: scale(0.75);
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
`;

export default (props) => (
  <SpinStyle {...props}>
    <div className="la-ball-beat">
      <div />
      <div />
      <div />
    </div>
  </SpinStyle>
);
