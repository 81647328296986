import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Form, { Field } from 'rc-field-form';
import { InputItem, Toast } from 'antd-mobile';
import Button from '../../component/Button';
import { List } from '../../component/styled';
import ImagePickerItem from '../../component/ImagePickerItem';
import PickerItem from '../../component/PickerItem';
import DatePickerItem from '../../component/DatePickerItem';
import TopBar from '../../component/TopBar';
import Block from '../../component/Block';
import Tip from '../../component/Tip';
import HairsPicker from './HairsPicker';
import styled from 'styled-components';
import StyledWrapper from './styled';

import request from '../../common/request';
import dayjs from 'dayjs';
import gender from '../../common/gender_data';
import getUrlSearchParams from '../../common/utils/getUrlSearchParams';

const StyledButton = styled(Button)`
  padding-left: 2.36rem;
  padding-right: 2.36rem;
`;

// 获取对应性别造型
function getModels(gender, success) {
  request
    .get(`/weixin/models?gender=${gender}`)
    .then((res) => {
      if (res.data.code === 0) {
        success(res.data.data);
      } else {
        Toast.fail(res.data.msg);
      }
    })
    .catch(() => {
      Toast.fail('网络异常');
    });
}

// 获取已提交用户信息
function getUploadedInfo(goodsId, success) {
  request
    .get(`/weixin/preview/user/${goodsId}`)
    .then((res) => {
      if (res.data.code === 0) {
        success(res.data.data);
      } else {
        Toast.fail(res.data.msg);
      }
    })
    .catch(() => {
      Toast.fail('网络异常');
    });
}

// 校验表单数据
function checkFormChild(values) {
  if (!values.nickname) {
    return Toast.fail('宝贝昵称不能为空');
  }
  if (!values.birthday) {
    return Toast.fail('宝贝生日不能为空');
  }
  if (!values.gender) {
    return Toast.fail('宝贝性别不能为空');
  }
  if (!values.photo || values.photo.length === 0) {
    return Toast.fail('宝贝照片不能为空');
  }
  if (!values.modelId) {
    return Toast.fail('发型不能为空');
  }
}

// 提交预览信息
const submit = (values, success) => {
  const {
    nickname,
    birthday,
    gender: [gender],
    photo: [file] = [],
    modelId,
    orderId,
    signature
  } = values || {};
  const form = new FormData();
  form.append('order_id', orderId);
  form.append('nickname', nickname);
  form.append('birthday', dayjs(birthday).format('YYYY-MM-DD'));
  form.append('gender', gender);
  form.append('photo', file.file || file.url);
  form.append('model_id', modelId);
  if (signature) form.append('sign', signature);
  Toast.loading('提交中...', 0);
  request({
    url: '/weixin/order/update',
    method: 'POST',
    data: form,
    contentType: 'multipart/form-data',
    withCredentials: true,
    crossDomain: true
  })
    .then((res) => {
      if (res.data.code === 0) {
        Toast.success('提交成功', 1, success);
      } else {
        Toast.fail(res.data.msg);
      }
    })
    .catch(() => {
      Toast.fail('网络异常');
    });
};

export default function Custom() {
  const history = useHistory();
  const [form] = Form.useForm();
  const { goodsId, orderId } = getUrlSearchParams();
  const handleCustom = async () => {
    try {
      const values = await form.validateFields();
      submit({ ...values, orderId }, () => {
        history.push('/ol');
      });
    } catch (errors) {
      checkFormChild(errors.values);
    }
  };
  useEffect(() => {
    getUploadedInfo(goodsId, (result) => {
      if (!result.nickname) return;
      const { nickname, birthday, gender, photo } = result;
      onGenderType(gender);
      form.setFields([
        {
          name: 'nickname',
          value: nickname
        },
        {
          name: 'birthday',
          value: birthday ? new Date(birthday.replace(/-/g, '/')) : undefined
        },
        {
          name: 'gender',
          value: [Number(gender)]
        },
        {
          name: 'photo',
          value: [
            {
              url: photo
            }
          ]
        }
      ]);
    });
  }, [form, goodsId]);
  // gender
  const [hairs, setHairs] = useState([]);
  const [genderType, onGenderType] = useState();
  useEffect(() => {
    if (genderType)
      getModels(genderType, (res) => {
        setHairs(res);
      });
  }, [genderType]);
  return (
    <StyledWrapper className="animate__animated animate__slideInDown">
      <Form form={form}>
        <TopBar title="个性化定制信息" />
        <Block type="form" title="宝贝信息">
          <List>
            <Field
              name="nickname"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputItem placeholder="请输入宝贝昵称" maxLength="7">
                宝贝昵称
              </InputItem>
            </Field>
            <Field
              name="birthday"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <DatePickerItem>出生日期</DatePickerItem>
            </Field>
            <Field
              name="gender"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <PickerItem data={gender} cols="1" onChange={([g]) => onGenderType(g)}>
                宝贝性别
              </PickerItem>
            </Field>
            <Field
              name="photo"
              valuePropName="files"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <ImagePickerItem>宝贝照片</ImagePickerItem>
            </Field>
          </List>
        </Block>
        {hairs.length > 0 ? (
          <Block className="hairs" title="选择发型">
            <Field
              name="modelId"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <HairsPicker data={hairs} />
            </Field>
          </Block>
        ) : null}
        <Block type="form" title="扉页定制">
          <Field name="signature" initialValue="爱你的爸爸妈妈">
            <InputItem placeholder="不填写则不署名" maxLength={7}>
              家长署名
            </InputItem>
          </Field>
        </Block>
        <Block>
          <Tip tip="你可以修改或删除署名，最多不超过7个字" />
        </Block>
        <div className="btn animate__animated animate__slideInUp">
          <StyledButton onClick={handleCustom}>确认提交</StyledButton>
        </div>
      </Form>
    </StyledWrapper>
  );
}
