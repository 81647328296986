import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import musicPlayIcon from '../../asset/img/musicPlay.png';
import SildeIcon from '../../asset/img/silde.png';
import './index.css';
// import Button from '../../component/Button';
// import styled from 'styled-components';
import StyledWrapper from './styled';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'animate.css';

import axios from 'axios';
import request from '../../common/request';
import qs from 'querystring';
import getUrlSearchParams from '../../common/utils/getUrlSearchParams';
import AudioMemo from './AudioMemo';
import Preferential from './Preferential';

const gender_model = {
  1: [2, 1],
  2: [3, 4]
};

// Install modules
SwiperCore.use([Pagination]);

// 获取当前页码图片
function fetch(api, options = {}) {
  return new Promise((resolve, reject) => {
    request
      .get(api, options)
      .then((res) => {
        if (res.data.code === 0) {
          const haveAudio = res.data.data.audioText ? res.data.data.audioUrl : true;
          if (res.data.data.image && res.data.data.image.indexOf('http') > -1 && haveAudio) {
            resolve(res.data.data);
          } else {
            reject('图片获取失败');
          }
        }
        reject(res.data.msg);
      })
      .catch(reject);
  });
}

export default function Preview() {
  const history = useHistory();
  const {
    goodsId, // 订单ID
    tab = 1, // 切换路径
    gender = 1, // 身份
    modelId //  造型
  } = getUrlSearchParams();
  // 图片序号
  const [tabKey, setTabKey] = useState(Number(tab));
  const models = gender_model[Number(gender)];
  // 造型
  const [modelKey, setModelKey] = useState(modelId || 0);
  const [showModal, setShowModal] = useState(false);
  const [showHint, setShowHint] = useState(true);
  const handleOrderClick = () => {
    history.push(`/order?goodsId=${goodsId}`);
  };
  // 切换图片
  const replaceTabImg = (tabIndex) => {
    setTabKey(tabIndex);
    // if (tabIndex == 6) {
    //   getCoupon();
    // }
    history.replace(
      `${location.pathname}?${qs.encode({ ...getUrlSearchParams(), tab: tabIndex })}`
    );
  };
  // 切换发型
  const replaceModel = () => {
    const currentModelKey = 1 ^ modelKey;
    setModelKey(currentModelKey);
    history.replace(
      `${location.pathname}?${qs.encode({
        ...getUrlSearchParams(),
        modelId: currentModelKey
      })}`
    );
  };

  const [images, setImages] = useState(new Array(17).fill(''));
  const [audioList, setAudioList] = useState([]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    let timeOut = null;
    let getImage = (requestNum = 1) => {
      console.log(tabKey + 1);
      clearTimeout(timeOut);
      timeOut = null;
      fetch(
        `/weixin/preview?${qs.encode({
          goods_id: goodsId,
          model_id: models[modelKey],
          page: tabKey + 1
        })}`,
        {
          timeout: 10 * 1000,
          cancelToken: source.token
        }
      )
        .then(({ image, audioUrl, audioText }) => {
          const newImages = [].concat(images);
          const newAudioList = [].concat(audioList);
          newImages[tabKey] = image;
          newAudioList[tabKey] = { audio: audioUrl, text: audioText };
          setImages(newImages);
          setAudioList(newAudioList);
        })
        // eslint-disable-next-line no-unused-vars
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
          } else if (requestNum < 17) {
            timeOut = setTimeout(() => {
              getImage(requestNum + 1);
            }, 1000);
          }
        });
    };
    getImage();
    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [tabKey, modelKey]);

  // 查询及领取优惠卷
  const getCoupon = (type = 1, isSeven = true) => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    // type = 1 查询是否有优惠卷，type = 2 领取优惠卷
    request
      .get(`/weixin/coupon?${qs.encode({ type })}`, {
        timeout: 10 * 1000,
        cancelToken: source.token
      })
      .then((res) => {
        const {
          code,
          data: { data }
        } = res.data;
        if (code === 0) {
          if (type == 1) {
            if (data && !isSeven) {
              handleOrderClick();
            }
            !data && setShowModal(true);
          } else {
            handleOrderClick();
          }
        }
      });
  };

  return (
    <StyledWrapper className="animate__animated animate__slideInDown">
      <div className="slide">
        <Swiper
          initialSlide={tabKey}
          effect="cube"
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          }}
          spaceBetween={50}
          onSlideChange={(swiper) => replaceTabImg(swiper.activeIndex)}
        >
          {images.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="img">
                {item ? (
                  <img src={item} alt={`预览图`} />
                ) : (
                  <>
                    <p>AI正在为你定制内容</p>
                    <p>大约需要15秒左右，请稍候片刻...</p>
                    <p>如等待过久，点击换发型可刷新</p>
                  </>
                )}
              </div>
            </SwiperSlide>
          ))}
          {/* <div className="swiper-pagination"></div> */}
        </Swiper>
      </div>
      <div className="btns animate__animated animate__slideInUp">
        <div onClick={replaceModel}>换发型</div>
        <div
          onClick={() => {
            // getCoupon(1, false);
            handleOrderClick();
          }}
        >
          定制个性化剧本
        </div>
      </div>
      <AudioMemo
        src={audioList[tabKey]?.audio}
        isLast={() => {
          if (tabKey == images.length - 1) {
            // getCoupon(1, false);
          }
        }}
      />
      <div className="pageNum">
        {tabKey + 1}/{images?.length}
      </div>
      <div className="imgText">{audioList[tabKey]?.text}</div>
      {showModal && (
        <Preferential
          tabKey={tabKey}
          onOk={() => getCoupon(2)}
          onClose={() => setShowModal(false)}
        />
      )}
      {showHint && (
        <div className="playMusicHint" onClick={() => setShowHint(false)}>
          <img src={musicPlayIcon} alt="" />
          <div>
            <img src={SildeIcon} alt="" />
          </div>
        </div>
      )}
    </StyledWrapper>
  );
}
