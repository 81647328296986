import styled from 'styled-components';

const StyledTip = styled.div`
  font-size: 0.24rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.34rem;
  position: relative;
  padding-left: 0.18rem;
  &:not(:last-child) {
    margin-bottom: 0.12rem;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.14rem;
    display: block;
    width: 0.06rem;
    height: 0.06rem;
    background-color: #ff5300;
  }
`;
export default function Tip({ tip = '' }) {
  const ts = Array.isArray(tip) ? tip : [tip];
  return (
    <>
      {ts.map((t) => {
        return <StyledTip key={t}>{t}</StyledTip>;
      })}
    </>
  );
}
