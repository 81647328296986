import React from 'react';
import { Carousel } from 'antd-mobile';
// import { useState } from '_react@17.0.2@react/cjs/react.production.min';

const Carousels = ({ list, width = '6.78rem', height = '5.6rem' }) => {
  return (
    <div style={{ width: width, margin: 'auto' }}>
      <Carousel
        autoplay={true}
        infinite
        beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
        afterChange={(index) => console.log('slide to', index)}
        dotStyle={{
          width: '0.3rem',
          height: '0.06rem',
          background: '#FFFFFF',
          borderRadius: '0.01rem',
          opacity: 0.4,
          marginBottom: '0.18rem'
        }}
        dotActiveStyle={{
          width: '0.3rem',
          height: '0.06rem',
          background: '#FFFFFF',
          borderRadius: '0.01rem',
          marginBottom: '0.18rem'
        }}
      >
        {list.map((val) => (
          <a
            key={val}
            href="javascript:voild(0)"
            style={{
              display: 'inline-block',
              width: '100%',
              height: height,
              overflow: 'hidden',
              borderRadius: '.15rem'
            }}
          >
            <img
              src={val}
              alt=""
              style={{ width: '100%', verticalAlign: 'top' }}
              onLoad={() => {
                window.dispatchEvent(new Event('resize'));
              }}
            />
          </a>
        ))}
      </Carousel>
    </div>
  );
};

export default Carousels;
