import styled from 'styled-components';
import { List, ImagePicker } from 'antd-mobile';
import { cropper } from './ImageCropper';
import dataURLtoBlob from '../common/utils/blob';

const StyledImagePicker = styled(ImagePicker)`
  width: 1.46rem;
  height: 1.46rem;
  .am-image-picker-list {
    padding: 0;
    margin: 0;
    .am-image-picker-upload-btn {
      border: none;
      background: #f2f2f2;
    }
    .am-image-picker-upload-btn::before,
    .am-image-picker-upload-btn::after {
      background-color: #9f9f9f;
    }
    .am-image-picker-item-content,
    .am-image-picker-upload-btn {
      border-radius: 0;
    }
  }
`;

const imgNormalize = (url) =>
  new Promise((resolve, reject) => {
    cropper(
      url,
      (base64) => {
        resolve({
          file: new File([dataURLtoBlob(base64)], new Date().getTime() + '.png', {
            type: 'image/png'
          }),
          url: base64
        });
      },
      () => reject([])
    );
  });

const ImagePickerItem = ({ onChange, children, ...props }) => {
  const selectable = !props.files || props.files.length === 0;
  return (
    <List.Item
      align="top"
      extra={
        <StyledImagePicker
          length="1"
          selectable={selectable}
          {...props}
          onChange={async (files) => {
            if (files.length === 0) {
              return onChange([]);
            }
            onChange([await imgNormalize(files[0].url)]);
          }}
        />
      }
    >
      {children}
    </List.Item>
  );
};

export default ImagePickerItem;
