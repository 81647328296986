import React from 'react';
import styled from 'styled-components';
import Coupon from '../../asset/img/coupon.png';

const PreferentialWarper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  img {
    width: 5rem;
  }
`;

const Preferential = ({ onClose, onOk, tabKey }) => {
  const okBtn = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    onOk();
  };

  return (
    <PreferentialWarper
      onClick={() => {
        tabKey == 6 && onClose();
      }}
    >
      <div>
        <img onClick={okBtn} src={Coupon} alt="" />
      </div>
    </PreferentialWarper>
  );
};

export default Preferential;
